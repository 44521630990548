import { useEffect, useState } from "react";
import {
    ListEventlogsByNodeIdQueryVariables,
    ModelSortDirection,
    ModelStringKeyConditionInput,
} from "../../API";
import { forEachInParallel } from "../../common/for-each-in-parallel";
import { Eventlog, EventlogTable, Node } from "../../model/database";
import { ListResult } from "./list-result";
import { useDatabase } from "./use-database";

export namespace useNodesEventlog {
    /**
     * {@link useNodesEventlog} コンポーネントのプロパティ定義
     */
    export type Options = {
        time?: ModelStringKeyConditionInput;
    };
}
/**
 * Node配列から各NodeのEventlogを取得する
 * @param targetNodes Node配列
 * @param options オプション
 * @returns
 */
export function useNodesEventlog(
    targetNodes: readonly Node[] | undefined,
    { time }: useNodesEventlog.Options = {},
): ListResult<Eventlog> {
    const eventlogTable = useDatabase().eventlogs;
    const [result, setResult] = useState<ListResult<Eventlog>>({
        data: [],
        loading: false,
    });

    useEffect(() => {
        if (!targetNodes) {
            setResult({ data: [], loading: false });
            return undefined;
        }

        const ac = new AbortController();
        setResult({ data: [], loading: true });
        fetchEventlogsInParallel({
            setResult,
            signal: ac.signal,
            table: eventlogTable,
            targetNodes,
            variables: {
                sortDirection: ModelSortDirection.DESC,
                time: time ?? null,
            },
        })
            .catch((error: unknown) => {
                if (ac.signal.aborted) {
                    return;
                }
                setResult((prev) =>
                    prev.error === undefined ? { ...prev, error } : prev,
                );
            })
            .finally(() => {
                setResult((prev) =>
                    prev.loading ? { ...prev, loading: false } : prev,
                );
            });

        return () => {
            ac.abort();
        };
    }, [eventlogTable, targetNodes, time]);

    return result;
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

type FindParameters = Parameters<EventlogTable["find"]>[0];
type EventlogSetter = (
    update: (prev: ListResult<Eventlog>) => ListResult<Eventlog>,
) => void;

interface ParallelEventlogFetchParameters {
    readonly setResult: EventlogSetter;
    readonly signal: AbortSignal;
    readonly table: EventlogTable;
    readonly targetNodes: readonly Node[];
    readonly variables: ListEventlogsByNodeIdQueryVariables;
}

interface EventlogFetchParameters {
    readonly parameters: FindParameters;
    readonly setResult: EventlogSetter;
    readonly signal: AbortSignal;
    readonly table: EventlogTable;
}

async function fetchEventlogsInParallel({
    setResult,
    signal,
    table,
    targetNodes,
    variables,
}: ParallelEventlogFetchParameters) {
    await forEachInParallel(targetNodes, async (node) => {
        await fetchEventlogs({
            parameters: {
                ...variables,
                index: "listEventlogsByNodeId",
                nodeId: node.id,
            },
            setResult,
            signal,
            table,
        });
    });
}

/**
 * 条件にマッチするイベントログを全て取得します。
 *
 * 取得したはしから結果に詰め込みます。
 *
 * @param parameters パラメーター
 */
async function fetchEventlogs({
    parameters,
    setResult,
    signal,
    table,
}: EventlogFetchParameters): Promise<void> {
    let nextToken: string | undefined;
    let errored = false;
    do {
        try {
            // 取得する
            const result = await table.find(
                { ...parameters, nextToken },
                { signal },
            );
            signal.throwIfAborted();

            // 結果をマージする
            // eslint-disable-next-line no-loop-func -- 誤検知
            setResult((prev) => {
                if (prev.error !== undefined) {
                    // よそでエラーが起きていたら終わりにする
                    errored = true;
                }
                return {
                    ...prev,
                    data: [...prev.data, ...result.items].sort(compareByTime),
                };
            });

            // 次へ
            nextToken = result.nextToken;
        } catch (error: unknown) {
            if (error instanceof DOMException && error.name === "AbortError") {
                // 中止なので無視
            } else {
                console.error("Eventlog取得に失敗しました。", {
                    parameters,
                    error,
                });
                setResult((prev) =>
                    prev.error === undefined ? { ...prev, error } : prev,
                );
            }
            break;
        }
    } while (nextToken !== undefined && !errored);
}

/**
 * ふたつのイベントログを、時刻の降順に並ぶように比較します。
 *
 * @param a イベントログ 1
 * @param b イベントログ 2
 * @returns 比較結果
 */
function compareByTime(a: Eventlog, b: Eventlog): number {
    return b.time.localeCompare(a.time, "en");
}
