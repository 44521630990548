/**
 * 非同期イテレーターが列挙するアイテムを並列処理します。
 *
 * 同時処理数が6を超えると一時停止します。
 * 同時処理している6タスクのいずれかが完了すると、再開して続きを実施します。
 *
 * @param it 非同期イテレーター
 * @param handler 列挙されたアイテムを処理する関数
 */
export async function forEachInParallel<T>(
    it: AsyncIterable<T> | Iterable<T>,
    handler: (value: T) => Promise<void>,
): Promise<void> {
    const processing: Set<Promise<unknown>> = new Set();
    const errors: unknown[] = [];
    let resume: ((...args: unknown[]) => void) | undefined;

    /* eslint-disable no-loop-func */
    for await (const item of it) {
        if (processing.size > 6) {
            await new Promise((resolve) => {
                resume = () => {
                    resume = undefined;
                    resolve(undefined);
                };
            });
        }

        const promise = handler(item)
            .catch((error: unknown) => {
                errors.push(error);
            })
            .finally(() => {
                processing.delete(promise);
                resume?.();
            });
        processing.add(promise);
    }
    /* eslint-enable no-loop-func */

    await Promise.all([...processing]);

    if (errors.length > 0) {
        throw new AggregateError(errors);
    }
}
